<template>
  <div class="xieyibox">
    <h2 class="zhengce">你拖我拉隐私政策</h2>
    <p class="zhengceTit">
      你拖我拉共享科技（浙江）有限公司（以下简称“你拖我拉”、“我们”）尊重并保护个
      ⼈信息，并且将以⾼度勤勉和审慎的义务对待这些信息。
      在您访问和使⽤你拖我拉提供的
      服务时，我们将按照本隐私协议（以下简称“本协议”）收集、处理及披露您的信息。我们希望
      通过本协议向您清晰地介绍我们对您信息的处理⽅式，以帮助您了解维护⾃⼰个⼈信息的⽅式。
      在接受本协议之前，请您仔细阅读全部内容，
    </p>
    <p class="zhengceTit">
      在确认充分理解并同意后开始使⽤。如果您或者您的监护⼈不同意本隐私政策的任何内容，您应
      该⽴即停⽌使⽤。如您⽆法准确理解或不同意本协议的任⼀内容，请不要访问、使⽤你拖我拉
      任何服务。您通过⽹络⻚⾯点击确认、实际使⽤你拖我拉服务等
    </p>
    <p class="zhengceTit">
      操作均表⽰您已阅读并充分理解本协议之内容，受其约束。本协议属于《你拖我拉服务协议》不可分割的
      ⼀部分。如对本政策内容有任何疑问、意⻅或建议，您可通过本协议中提供的各种联系⽅式与我们联系
    </p>
    <span class="biaoti">本协议将帮助您了解以下内容:</span>
    <p class="zhengceTit">1. 本协议的适⽤范围</p>
    <p class="zhengceTit">2. 我们如何收集和使⽤您的个⼈信息</p>
    <p class="zhengceTit">3. 我们如何使⽤cookie和同类技术</p>
    <p class="zhengceTit">4. 我们如何共享、转让、公开披露您的个⼈信息</p>
    <p class="zhengceTit">5. 我们如何保护和保存您的个⼈信息</p>
    <p class="zhengceTit">6. 您的权利</p>
    <p class="zhengceTit">7. 我们如何处理未成年⼈的个⼈信息</p>
    <p class="zhengceTit">8. 您的个⼈信息如何在全球范围转移</p>
    <p class="zhengceTit">9. 本协议如何更新</p>
    <p class="zhengceTit">10. 如何联系我们</p>
    <span class="xiaoti">1. 本协议的适⽤范围</span>
    <p class="zhengceTit">
      当您同意本协议并开始使⽤我们的任何产品和服务时，本协议即适⽤，⽆论该产品和服务是否单独设
      置了隐私协议，也⽆论您是浏览⽤⼾（访客）还是注册登录⽤⼾。请您注意，本协议不适⽤于以下情况：
    </p>
    <p class="zhengceTit">
      •
      通过我们的产品和/或服务⽽接⼊的第三⽅服务（包括任何第三⽅⽹站）收集的信息；
    </p>
    <p class="zhengceTit">
      • 通过在我们产品和/或服务中进⾏⼴告服务的其他公司或机构所收集的信息。
    </p>
    <p class="zhengceTit">
      请您知悉：如果您浏览第三⽅⽹站或使⽤第三⽅的产品和/或服务时向该第三⽅提供您的个⼈信息，
      您的信息应当适⽤该第三⽅的隐私声明或类似协议，我们对任何第三⽅不当使⽤或披露由您提供的信
      息不承担任何法律责任，⽆论您登录或浏览上述⽹站、软件，或使⽤其产品和或服务是否基于你拖我拉的链接或引导。
      强烈建议您在使⽤上述第三⽅服务前先⾏了解、确认服务提供⽅的隐私保护情况。
    </p>
    <span class="xiaoti">2. 我们如何收集和使⽤您的个⼈信息</span>
    <p class="zhengceTit">（⼀）我们收集您的个⼈信息的情形</p>
    <p class="zhengceTit">
      “个⼈信息”指个⼈信息是以电⼦或者其他⽅式记录的能够单独或者与其他信息结合识别特定⾃然⼈
      的各种信息，包括但不限于个⼈⾝份信息（如姓名）、⽹络⾝份识别信息（如账号名、邮箱地址、⽹
      络IP）、设备信息（Mac地址）。“⾮个⼈⾝份信息”指任何与您有关但实际上不可直接或间接辨认
      您⾝份的信息，包括以汇集、匿名或化名⽅式提供的个⼈信息。您知悉并同意，⽆法识别出您个⼈的
      信息不属于个⼈信息。
    </p>
    <p class="zhengceTit">
      您所属组织提供的与您相关的个⼈信息。您所属组织在为了让您使⽤产品和/或服务⽽开设您的账⼾
      时，将会提供与您相关的若⼲个⼈信息，包括您的账⼾名、电子邮箱、部⻔组织架构、职位。您知悉
      并确认您所属组织向我们提交的上述信息已经基于您的明确授权。
    </p>
    <p class="zhengceTit">
      我们提供产品和/或服务时，可能会收集、储存下列与您有关的信息。如果您不提供相关信息，可能
      ⽆法注册成为我们的⽤⼾或⽆法使⽤和享受我们提供的某些产品和服务，或者⽆法达到相关产品和/
      或服务拟达到的效果。
    </p>
    <p class="zhengceTit">2.1. 帮助您成为我们的会员</p>
    <p class="zhengceTit">
      2.1.1.当您在你拖我拉⽹站创建账⼾时，您须向我们提供会员名，设置、确认您的登录密码，提供您在中
      华⼈⺠共和国境内⼿机号码或电⼦邮箱账⼾。您提交的⼿机号码或邮箱账⼾⽤于您注册、登录、绑定
      账⼾、密码找回时接受验证码，并且作为您与你拖我拉指定的联系⽅式之⼀，⽤来接受相关业务通知
      （如新品上线、服务变更等）。
    </p>
    <p class="zhengceTit">
      您知悉并同意，为帮助您更便捷地体验并使⽤你拖我拉提供的其他产品或服务，我们将在获得您授权
      的情况下将您所创建的账号及相关信息⽤于帮助您⽣成对应应⽤的账号。
    </p>
    <p class="zhengceTit">
      2.1.2.在您注册完成你拖我拉账⼾后，您可以⾃主选择在账⼾中继续填写您的信息，包括（1）您的其他
      个⼈信息，如性别、⼯作单位等;（2）联系信息，如地址、联系电话;（3）绑定您的微信。您若不提
      供这些信息，将不会影响您对你拖我拉⼀般服务的使⽤。您提供的本条第（1）类信息，会帮助我们更了
      解您对于你拖我拉服务需求，以便你拖我拉有针对性地向您推⼴、介绍产品；您提供的本条第（2）
      类信息，会帮助我们通过如上⽅式向您推⼴、宣传产品、邮寄业务通知（含账单）或与您进⾏业务沟
      通；您提供的本条第（3）类信息，会帮助我们为您实现微信消息的提醒功能。
    </p>
    <p class="zhengceTit">
      2.1.3.如果您仅需使⽤你拖我拉⽹站的浏览、搜索等基本服务，您不需要注册成为我们的会员及提供上述
      信息
    </p>
    <p class="zhengceTit">2.2. 向您提供服务</p>
    <p class="zhengceTit">
      2.2.1.
      你拖我拉可能会根据相关法律规定要求您提供真实⾝份信息以进⾏实名认证，您应通过您的账
      号提供您的真实⾝份信息。为⽅便您提供此等信息，你拖我拉为您提供了基于⼿机号码的实名认证，
      将收集您的⼿机号码，您可以根据您的⾝份属性选择适合的⽅式。您理解并同意你拖我拉有权⾃⾏或
      委托第三⽅，审查您在实名认证时提供的信息是否真实、准确及有效。
    </p>
    <p class="zhengceTit">2.2.2. 在您使⽤服务过程中，我们会收集以下的信息：</p>
    <p class="zhengceTit">
      2.2.2.1.
      设备信息：我们会根据您在软件安装及使⽤中授予的具体权限，接收并记录您所使⽤的设备
      相关信息（例如设备型号、操作系统版本、设备设置、唯⼀设备标识符等软硬件特
      征信息）、设备所在位置相关信息（包括IP地址、GPS位置以及能够提供相关信息的Wi-Fi接⼊点、
      蓝⽛和基站等传感器信息）。我们可能会将前⾯两类信息进⾏关联，以便我们能在不同设备上为您提
      供⼀致的服务。
    </p>
    <p class="zhengceTit">
      2.2.2.2.
      ⽇志信息：当您使⽤我们的⽹站或客⼾端提供的服务时，我们会⾃动收集您对我们服务的详
      细使⽤情况，作为有关⽹络⽇志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营
      商、使⽤的语⾔、访问⽇期和时间及您访问的⽹⻚记录等。
    </p>
    <p class="zhengceTit">
      2.2.2.3.
      ⽤⼾账⼾的⽀持信息：基于您使⽤你拖我拉服务⽽产⽣的⽤⼾的咨询记录、报障记录和针对⽤⼾
      故障的排障过程（包括通信或通话记录），你拖我拉将通过记录、分析这些信息以便更及时响应您
      的帮助请求，以及⽤于改进服务。
    </p>
    <p class="zhengceTit">
      2.2.2.4.
      录⾳、录像、照⽚信息：指您使⽤我们的特定功能（包括语⾳搜索、扫码、拍照）时，在获
      得您针对调取特定权限的授权后，我们将收集上述信息。拒绝提供该信息仅会使您⽆法使⽤上述功
      能，但不影响您正常使⽤我们的产品和/或服务的其他功能。此外，
      您可随时在移动设备的【设置-权 限管理】关闭相关功能。
    </p>
    <p class="zhengceTit">
      2.2.2.5.
      位置信息：指您开启设备定位功能并使⽤我们基于位置提供的相关产品和/或服务时，我们收
      集的有关您位置的信息，包括：您通过具有定位功能的移动设备使⽤我们的产品和/或服务时，我们
      通过GPS或WiFi等⽅式收集的您的地理位置信息。您可以通过关闭定位功能随时停⽌我们对您的地理
      位置信息的收集，但之后您可能将⽆法使⽤相关服务或功能，或者⽆法达到相关服务拟达到的效果。
      关闭定位功能后，您可以通过⼿动输⼊地理位置的⽅式获取对应基于地理位置的你拖我拉服务。
    </p>
    <p class="zhengceTit">
      2.2.3.我们在向您服务时，我们会按照本协议的约定收集、使⽤、存储、对外提供及保护您的个⼈信
      息；超出本协议约定收集您的个⼈信息的，我们会另⾏向您说明信息收集的范围与⽬的，并征得您的
      同意后收集提供相应服务所必要的信息；如您选择不提供前述信息，将会影响到您使⽤相应服务。
    </p>
    <p class="zhengceTit">2.3. 其他⽅分享的您的信息</p>
    <p class="zhengceTit">
      2.3.1.当您使⽤我们的关联公司、合作伙伴的服务时，他们在您的授权下可能向我们分享您的账号信
      息；
    </p>
    <p class="zhengceTit">
      2.3.2.若您以其他⽅式关联登录、使⽤我们的服务，我们会向第三⽅请求您的个⼈信息，对于我们需
      要但第三⽅⽆法提供的个⼈信息，我们仍会要求您提供。如果您拒绝提供，将可能导致您⽆法正常使
      ⽤我们的产品和/或服务的某些功能；如果您同意提供，此时，您将授权我们读取您在该第三⽅账号
      注册的相关信息（如昵称、头像）；
    </p>
    <p class="zhengceTit">
      2.3.3.我们将会依据相关法律法规要求以及与第三⽅的约定、对个⼈信息来源的合法性进⾏确认后，
      在符合相关法律法规规定的前提下，使⽤从第三⽅获得的您的个⼈信息。
    </p>
    <p class="zhengceTit">
      2.4.
      我们希望提供给您的产品和/或服务是完善的，所以我们会不断改进我们的产品和/或服务，包括
      技术。这意味着我们的产品和/或服务可能会经常推出新功能，可能需要收集新的信息。如果我们收
      集的个⼈信息或使⽤⽅式出现变化或调整，我们会通过适当的⽅式告知您，并且可能以修订本协议的
      ⽅式再次征询您的授权。
    </p>
    <p class="zhengceTit">
      2.5.
      我们依照法律法规的规定，将您的个⼈信息存储于中华⼈⺠共和国境内。如需跨境存储，我们会
      单独再征得您的授权同意。
    </p>
    <p class="zhengceTit">
      2.6.
      您授权我们在您使⽤我们的产品及服务的期间使⽤您的个⼈信息，若您注销账号，我们将停⽌使
      ⽤并在合理期限内删除或者匿名化您的个⼈信息。但基于相关法律法规要求（如《电⼦商务法》要求
      留存交易订单信息⾄少三年）或者解决投诉或者争议的客观需要，部分信息将继续保留特定时间，直
      ⾄法律规定期限届满或者信息保留的⽬的不再必要。此外请您注意，由于匿名化后的信息⽆法识别或
      者关联您，因此不属于个⼈信息，对于已匿名化的信息的存储期限和处理，我们⽆需取得您的授权同
      意，也⽆需作出通知。
    </p>
    <p class="zhengceTit">（⼆）我们使⽤您的个⼈信息的情形</p>
    <p class="zhengceTit">
      2.7. 我们出于如下⽬的，使⽤您提交或者我们主动收集的个⼈信息：
    </p>
    <p class="zhengceTit">
      2.7.1.为了向您提供服务，我们会向您发送必要的信息、通知或与您进⾏业务沟通，包括但不限于为
      保证服务完成所必须的验证码、使⽤服务时所必要的推送通知；
    </p>
    <p class="zhengceTit">
      2.7.2.⽤于数据分析，以便向您提供更加优质的产品和/或服务进⼀步了解您如何接⼊和使⽤我们的产
      品和/或服务，从⽽针对性地回应您的个性化需求，例如语⾔设定、位置设定、个性化的帮助服务和
      指⽰，或对您和其他⽤⼾作出其他⽅⾯的回应；
    </p>
    <p class="zhengceTit">
      2.7.3.为了维护、改进服务，向您提供更符合您个性化需求的信息展⽰，我们利⽤您的服务使⽤信息
      做出特征模型并进⾏⽤⼾画像，向您展⽰、推送信息和可能的商业⼴告，包括但不限于关于你拖我拉
      产品的新闻以及市场活动及优惠促销信息、你拖我拉合作第三⽅的推⼴信息，或其他您可能感兴趣的
      内容。以上信息会明确标注“定制”字样，如果您不想接收我们给您发送的商业性电⼦信息，您可通
      过短信提⽰回复退订或根据信息中提供的退订⽅式撤回同意；
    </p>
    <p class="zhengceTit">
      2.7.4.我们可能以个⼈信息统计数据为基础，设计、开发、推⼴全新的产品及服务；我们会对我们的
      服务使⽤情况进⾏统计，并可能会与公众或第三⽅分享这些统计信息，但这些统计信息不包含您的任
      何⾝份识别信息；
    </p>
    <p class="zhengceTit">
      2.7.5.为提⾼您使⽤我们及我们关联公司、合作伙伴提供服务的安全性，确保操作环境安全与识别账
      号异常状态，保护您或其他⽤⼾或公众的⼈⾝财产安全免遭侵害，更好地预防钓⻥⽹站、欺诈、⽹络
      漏洞、计算机病毒、⽹络攻击、⽹络侵⼊等安全⻛险，更准确地识别违反法律法规或你拖我拉相关协
      议、规则的情况，我们可能使⽤您的会员信息、并整合设备信息、有关⽹络⽇志以及我们关联公司、
      合作伙伴合法分享的信息，来判断您账⼾及交易⻛险、进⾏⾝份验证、安全事件的检测及防范，并依
      法采取必要的 记录、审计、分析、处置措施；
    </p>
    <p class="zhengceTit">2.7.6. 软件认证或管理软件升级；</p>
    <p class="zhengceTit">
      2.7.7.让您参与有关我们产品和服务或通过我们的产品和服务发起的调查，是否参与调查将由您全权
      决定，并且由您⾃⾏选择提供哪些信息；
    </p>
    <p class="zhengceTit">
      2.7.8.出于安全、合法调查等⽬的，我们可能使⽤您的数据
      做数据汇总、分析、挖掘（包括商业化利⽤），但这些信息都采⽤匿名的⽅式，不能识别您的⾝份；
    </p>
    <p class="zhengceTit">
      2.8.
      如超出收集个⼈信息时所声称的⽬的，或者在超出具有直接或合理关联的范围使⽤您的个⼈信息
      前，我们会再次向您告知并征得您的明⽰同意；
    </p>
    <p class="zhengceTit">
      2.9.
      为了向您提供更好的服务体验、改善我们的产品和/或服务或基于您所同意的其他⽤途，在符合
      相关法律法规的前提下，我们可能会在另⾏征得您同意的前提下将通过我们的某⼀项产品和/或服务
      所收集的信息，以汇集信息或者个性化的⽅式，⽤于您所使⽤的我们所提供的其他产品和/或服务。
      例如，在您使⽤我们的⼀项产品或服务时所收集的信息，可能在您所使⽤的另⼀产品或服务中⽤于向
      您提供特定内容，或向您展⽰与您相关的、⽽⾮普遍推送的信息。
    </p>
    <p class="zhengceTit">（三）事先征得授权同意的例外</p>
    <p class="zhengceTit">
      2.10.请注意：在以下情形中，收集、使⽤个⼈信息⽆需事先征得您的授权同意：
    </p>
    <p class="zhengceTit">2.10.1.与国家安全、国防安全直接相关的；</p>
    <p class="zhengceTit">
      2.10.2.与公共安全、公共卫⽣、重⼤公共利益直接相关的；
    </p>
    <p class="zhengceTit">
      2.10.3.与犯罪侦查、起诉、审判和判决执⾏等直接有关的；
    </p>
    <p class="zhengceTit">
      2.10.4.出于维护您或其他个⼈的⽣命、财产等重⼤合法权益但⼜很难得到您本⼈同意的；
    </p>
    <p class="zhengceTit">2.10.5.所收集的个⼈信息是您⾃⾏向社会公众公开的；</p>
    <p class="zhengceTit">
      2.10.6.从合法公开披露的信息中收集个⼈信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p class="zhengceTit">2.10.7.根据您的要求向您提供产品或服务所必需的；</p>
    <p class="zhengceTit">
      2.10.8.⽤于维护所提供的产品或服务的安全稳定运⾏所必需的，例如发现、处置产品或服务的故障；
    </p>
    <p class="zhengceTit">2.10.9.法律法规规定的其他情形。</p>
    <span class="xiaoti">3. 我们如何使⽤Cookie和同类技术</span>
    <p class="zhengceTit">
      3.1.
      为确保⽹站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您
      的计算机或移动设备上存储名为Cookie的⼩数据⽂件。Cookie通常包含标识符、站点名称以及⼀些
      号码和字符。你拖我拉只能读取你拖我拉提供的Cookies。
    </p>
    <p class="zhengceTit">
      3.2.
      借助于Cookie，能够存储您的偏好或购买清单中的付费服务等数据。当下⼀次您再次访问的时
      候，我们将显⽰您需要的信息；或由你拖我拉通过cookies⽂件来辨识您的来源⽹站，以便你拖我拉
      能追踪你拖我拉的⼴告效果。
    </p>
    <p class="zhengceTit">
      3.3.
      我们不会将Cookie⽤于本协议所述⽬的之外的任何⽤途。您可根据⾃⼰的偏好管理Cookie，您
      也可以清除计算机上保存的所有Cookie。⼤部分⽹络浏览器都设有阻⽌Cookie的功能。但如果您这
      么做，则需要在每⼀次访问我们的⽹站时更改⽤⼾设置。如需详细了解如何更改浏览器设置，请访问
      您使⽤的浏览器的相关设置⻚⾯。
    </p>
    <p class="zhengceTit">
      3.4.
      除Cookie外，我们有可能还会在⽹站上使⽤⽹站信标和像素标签等其他同类技术。例如，我们
      向您发送的电⼦邮件可能含有链接⾄我们⽹站内容的地址链接，如果您点击该链接，我们则会跟踪此
      次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客⼾服务体验。⽹站信标通常是⼀种
      嵌⼊到⽹站或电⼦邮件中的透明图像。借助于电⼦邮件中的像素标签，我们能够获知电⼦邮件是否被
      打开。如果您不希望⾃⼰的活动以这种⽅式被追踪，则可以随时从我们的寄信名单中退订。
    </p>
    <span class="xiaoti">4. 我们如何共享、转让、公开披露您的个⼈信息</span>
    <p class="zhengceTit">
      4.1. 共享和披露
      ⼀般情况下，我们不会与其他组织和个⼈共享您的个⼈信息，但以下情况除外：
    </p>
    <p class="zhengceTit">
      4.1.1.在获取明确同意的情况下共享：获得您的明确同意后，我们可能会基于为您提供特定服务需要
      时与其他⽅共享您的个⼈信息；
    </p>
    <p class="zhengceTit">
      4.1.2.与关联⽅及授权合作伙伴共享：我们可能委托受信赖的关联⽅或合作伙伴来提供服务，因此我
      们可能会与合作伙伴共享您的某些个⼈信息，以提供更好的客⼾服务和优化⽤⼾体验。我们仅会出于
      合法、正当、必要、特定、明确的⽬的共享您的个⼈信息，并且只会共享提供服务所必要的个⼈信
      息。我们的关联⽅及合作伙伴⽆权将共享的个⼈信息⽤于任何其他⽤途；
    </p>
    <p class="zhengceTit">
      4.1.3.在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按⾏政、司法
      机关依法提出的要求，对外共享您的个⼈信息；
    </p>
    <p class="zhengceTit">4.1.4. 法律法规规定的其他情形。</p>
    <p class="zhengceTit">4.2. 共享您的个⼈信息，我们将遵循如下原则</p>
    <p class="zhengceTit">
      4.2.1.授权同意原则：未经您的同意，我们不会共享您的个⼈信息，除⾮共享的个⼈信息是去标识化
      处理后的信息，且共享的第三⽅⽆法重新识别此类信息的⾃然⼈主体。如果第三⽅使⽤信息的⽬的超
      越原授权同意范围，他们需要重新征得您的同意。；
    </p>
    <p class="zhengceTit">
      4.2.2.合法正当与最⼩必要原则：共享的数据必须具有合法正当⽬的，且共享的数据以达成⽬的必要
      为限。
    </p>
    <p class="zhengceTit">
      4.2.3.安全审慎原则：我们将审慎评估第三⽅使⽤共享信息的⽬的，对这些合作⽅的安全保障能⼒进
      ⾏综合评估。我们会对合作⽅获取信息的软件⼯具开发包（SDK）、应⽤程序接⼝（API）进⾏严格
      的安全监测，以保护数据安全。
    </p>
    <p class="zhengceTit">4.3. 实现功能或服务的共享信息</p>
    <p class="zhengceTit">
      4.3.1.
      如您是企业/组织⽤⼾的最终⽤⼾，您所属的企业/组织⽤⼾管理员通过本软件选择开通、管理
      并使⽤第三⽅的产品或服务时，我们可能需要向该第三⽅服务提供商共享您开通、管理并使⽤第三⽅
      产品或服务所必要的信息，否则您将⽆法使⽤第三⽅服务，企业/组织⽤⼾管理员开通使⽤第三⽅服
      务时，需阅读并充分了解并遵守第三⽅服务的产品功能及隐私保护政策。如您作为个⼈⽤⼾通过本软
      件选择开通、管理并使⽤第三⽅的产品或服务时，您同意我们向该第三⽅服务提供商共享您开通、管
      理并使⽤第三⽅产品或服务所必要的的信息，否则将您⽆法使⽤第三⽅服务，您开通使⽤第三⽅服务
      时，需阅读并充分了解并遵守第三⽅服务的产品功能及隐私政策；
    </p>
    <p class="zhengceTit">
      4.3.2.登录、绑定本软件内产品：经您同意，当您使⽤你拖我拉账号登录第三⽅的产品或服务时，我们会
      将您的基础信息（姓名、头像）及其他您授权的信息与前述产品或服务共享。
    </p>
    <p class="zhengceTit">
      4.4. 转让
      我们不会将您的个⼈信息转让给任何公司、组织和个⼈，但以下情况除外：
    </p>
    <p class="zhengceTit">
      4.4.1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他⽅转让您的个⼈信息；
    </p>
    <p class="zhengceTit">
      4.4.2.在你拖我拉与其他法律主体者发⽣合并、收购或破产清算情形，或其他涉及合并、收购或破产
      清算情形时，如涉及到个⼈信息转让，我们会要求新的持有您个⼈信息的公司、组织继续受本协议的
      约束，否则我们将要求该公司、组织和个⼈重新向您征求授权同意。
    </p>
    <p class="zhengceTit">
      4.5. 公开披露我们仅会在以下情况下，公开披露您的个⼈信息：
    </p>
    <p class="zhengceTit">
      4.5.1.获得您明确同意或基于您的主动选择，我们可能会公开披露您的个⼈信息；
    </p>
    <p class="zhengceTit">
      4.5.2.或为保护你拖我拉平台及其关联⽅⽤⼾或公众的⼈⾝财产安全免遭侵害，我们可能依据适⽤的法律
      或你拖我拉平台相关协议、规则披露关于您的个⼈信息；
    </p>
    <p class="zhengceTit">
      4.5.3.基于法律的披露：在法律、法律程序、诉讼或政府主管部⻔强制性要求的情况下，我们可能会
      公开披露您的个⼈信息
    </p>
    <p class="zhengceTit">4.6. 您主动与他⼈共享的信息</p>
    <p class="zhengceTit">
      4.6.1.您可以通过我们的⽹站进⾏搜索问题上报、评价等，以帮助更多的⽤⼾享受到更精准的信息。
    </p>
    <p class="zhengceTit">
      4.6.2.您可以利⽤我们的分享功能与您的特定或不特定社交好友（如微信、微博）共享您的个⼈信
      息。请您在分享前充分考虑信息接收⼈的信誉情况，并建议您查看您利⽤的社交⽹络或第三⽅服务提
      供商的隐私声明，以了解他们如何处理您的信息，以便审慎决策
    </p>
    <p class="zhengceTit">
      4.7.
      共享、转让、公开披露个⼈信息时事先征得授权同意的例外以下情形中，共享、转让、公开披露
      您的个⼈信息⽆需事先征得您的授权同意：
    </p>
    <p class="zhengceTit">4.7.1. 与国家安全、国防安全有关的；</p>
    <p class="zhengceTit">4.7.2. 与公共安全、公共卫⽣、重⼤公共利益有关的；</p>
    <p class="zhengceTit">4.7.3. 与犯罪侦查、起诉、审判和判决执⾏等有关的；</p>
    <p class="zhengceTit">
      4.7.4.出于维护您或其他个⼈的⽣命、财产等重⼤合法权益但⼜很难得到本⼈同意的；
    </p>
    <p class="zhengceTit">4.7.5. 您⾃⾏向社会公众公开的个⼈信息；</p>
    <p class="zhengceTit">
      4.7.6.从合法公开披露的信息中收集个⼈信息的，如合法的新闻报道、政府信息公开等渠道。
    </p>
    <span class="xiaoti">5. 我们如何保护和保存您的个⼈信息</span>
    <p class="zhengceTit">
      5.1.
      你拖我拉⾮常重视您的信息安全。我们努⼒采取各种合理的物理、电⼦和管理⽅⾯的安全措施来
      保护您的个⼈信息。防⽌个⼈信息遭到未经授权访问、公开披露、使⽤、修改、损坏或丢失。我们会
      使⽤加密技术提⾼个⼈信息的安全性；我们会使⽤受信赖的保护机制防⽌个⼈信息遭到恶意攻击；我
      们会部署访问控制机制，尽⼒确保只有授权⼈员才可访问个⼈信息；以及我们会举办安全和隐私保护
      培训课程，加强员⼯对于保护个⼈信息重要性的认识。
    </p>
    <p class="zhengceTit">
      5.2.
      我们会采取合理可⾏的措施，尽⼒避免收集⽆关的个⼈信息。我们只会在达成本协议所述⽬的所
      需的期限内保留您的个⼈信息，除⾮受到法律的允许。超出上述个⼈信息保存期限后，我们会对您的
      个⼈信息进⾏删除或匿名化处理。
    </p>
    <p class="zhengceTit">
      5.3.
      请您理解，在互联⽹⾏业由于技术的限制和⻜速发展以及可能存在的各种恶意攻击⼿段，即便我
      们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。因此，我们强烈建议您采取积极
      措施保护个⼈信息的安全，包括但不限于使⽤复杂密码、定期修改密码、不将⾃⼰的账号密码等个⼈
      信息透露给他⼈。
    </p>
    <p class="zhengceTit">
      5.4.
      在不幸发⽣个⼈信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告
      知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可⾃主防范和降低⻛
      险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等⽅式告
      知您，难以逐⼀告知个⼈信息主体时，我们会采取合理、有效的⽅式发布公告。同时，我们还将按照
      监管部⻔要 求，上报个⼈信息安全事件的处置情况。
    </p>
    <p class="zhengceTit">
      5.5.
      我们提醒您注意，互联⽹并⾮绝对安全的环境，当您通过我们的⽹站中嵌⼊的第三⽅社交软件、
      电⼦邮件、短信等与其他⽤⼾交互您的地理位置或⾏踪轨迹信息时，不确定第三⽅软件对信息的传递
      是否完全加密，注意确保您个⼈信息的安全。
    </p>
    <p class="zhengceTit">
      5.6.
      如出现你拖我拉产品和服务停⽌运营的情形，我们会采取合理措施保护您个⼈信息安全，包括及时停
      ⽌继续收集个⼈信息的活动；停⽌运营的通知将以逐⼀送达或公告的形式通知⽤⼾；并对所持有的个
      ⼈信息进⾏删除或匿名化处理等。
    </p>
    <p class="zhengceTit">
      5.7.
      我们将收集到的您的个⼈信息存放在中华⼈⺠共和国境内，具体位置为阿⾥云存储服务器，阿⾥
      云将协助我们为您提供数据存储安全服务。如在符合适⽤法律规定的情形下因业务需要向境外传输个
      ⼈信息的，我们会事先征得您的单独同意，并向您告知个⼈信息出境的⽬的、接收⽅、安全保障措
      施、安全⻛险等情况。此外，我们还将履⾏法律法规规定的跨境提供个⼈信息的安全保障义务，可能
      包括通过数据跨境安全评估或认证、与境外接收⽅签订数据处理协议。
    </p>
    <span class="xiaoti">6. 您的权利</span>
    <p class="zhengceTit">6.1. 访问权</p>
    <p class="zhengceTit">您可以通过如下⽅式访问您的个⼈信息：</p>
    <p class="zhengceTit">
      6.1.1.账号信息：您可以登录你拖我拉⽹站或客⼾端查询、管理（更新、删除）使⽤你拖我拉服务时
      提交的基本信息（基本资料） 和联系⽅式等信息，进⾏账号关联或⾝份认证等。
    </p>
    <p class="zhengceTit">
      6.1.2.使⽤信息：您可以在我们的⽹站、客⼾端等服务中查阅使⽤记录信息等，您也可通过本协议⽂
      末提供的⽅式联系我们访问这些信息。请您注意，为了确保您本⼈⾏使权利的真实性，我们将在核实
      您的⾝份后提供，但法律法规另有约定的除外；
    </p>
    <p class="zhengceTit">
      6.1.3.其他信息：如您在此访问过程中遇到操作问题或如需获取其他前述⽆法获知的个⼈信息内容，
      您可通过本协议⽂末提供的⽅式联系我们，我们将在核实您的⾝份后提供，但法律法规另有约定的除
      外。
    </p>
    <p class="zhengceTit">6.2. 更正权</p>
    <p class="zhengceTit">
      6.2.1.如果您需要变更您你拖我拉账⼾的实名认证信息，您需通过客服、电话、邮件等⽅式联系你拖我拉，
      我们会协助您进⾏相应操作。
    </p>
    <p class="zhengceTit">
      6.2.2.经对您的⾝份进⾏验证，且更正不影响信息的客观性和准确性的情况下，您有权对错误或不完
      整的信息作出更正或更新，您可以⾃⾏在我们的⽹站或客⼾端中进⾏更正，或在特定情况下，尤其是
      数据错误时，通过本协议⽂末提供的联系⽅式将您的更正申请提交给我们，要求我们更正或更新您的
      数据，但法律法规另有规定的除外。但出于安全性和⾝份识别的考虑，您可能⽆法修改注册时提交的
      某些初始注册信息。
    </p>
    <p class="zhengceTit">6.3. 删除权</p>
    <p class="zhengceTit">
      根据相关规范，在以下情形中，您可以向我们提出删除个⼈信息的请求：
    </p>
    <p class="zhengceTit">
      6.3.1. 如果处理⽬的已实现或者为实现处理⽬的不再必要;
    </p>
    <p class="zhengceTit">
      6.3.2. 如果我们停⽌提供产品或者服务, 或者保存期限已届满;
    </p>
    <p class="zhengceTit">6.3.3. 如果您已撤回同意;</p>
    <p class="zhengceTit">
      6.3.4. 如果我们违反法律、 ⾏政法规或者违反约定处理您的个⼈信息；
    </p>
    <p class="zhengceTit">6.3.5. 法律、⾏政法规规定的其他情形</p>
    <p class="zhengceTit">
      您知悉并理解，为保障⾏使个⼈信息权利的安全，您可能需要提供书⾯请求，或以其他⽅式证明您的
      ⾝份。我们可能会先要求您验证⾃⼰的⾝份，然后再处理您的请求。
    </p>
    <p class="zhengceTit">
      若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个⼈信息的实体，要求其及时删
      除，除⾮法律法规另有规定，或这些实体获得您的独⽴授权。
    </p>
    <p class="zhengceTit">
      当您从我们的服务中删除信息后，我们将在合理期限内及时删除您的个⼈信或者对其进⾏匿名化处
      理。
    </p>
    <p class="zhengceTit">6.4. 复制权</p>
    <p class="zhengceTit">
      如您需要复制您的个⼈信息，您可以通过本协议⽂末提供的⽅式联系我们，在核实您的⾝份后，我们
      将向您提供您在我们的服务中的个⼈信息副本（例如基本资料、⾝份信息），但法律法规另有规定的
      除外。
    </p>
    <p class="zhengceTit">6.5. 撤回同意权</p>
    <p class="zhengceTit">
      6.5.1.如您想更改相关功能的授权范围（包括位置、通讯录、相机），您可以通过您的硬件设备修改
      个⼈设置、或在我们的产品或服务中的相关功能设置界⾯进⾏操作处理。如您在此过程中遇到操作问
      题，可以通过本协议⽂末提供的⽅式联系我们。
    </p>
    <p class="zhengceTit">
      6.5.2.当您撤回相关个⼈信息收集的授权后，我们将不再收集该信息，也⽆法再为您提供上述与之对
      应的服务；但您知悉并同意，除⾮您⾏使前述“删除权”，否则您的该⾏为不会影响我们基于您之前
      的授权进⾏的个⼈信息的处理、存储。
    </p>
    <p class="zhengceTit">6.6. 注销权</p>
    <p class="zhengceTit">
      6.6.1.您可以通过联系我们的客服或通过其他我们公⽰的⽅式注销您的账号（但法律法规另有规定的
      除外），⼀旦您注销账号，将⽆法使⽤我们提供的服务，因此请您谨慎操作。
    </p>
    <p class="zhengceTit">
      6.6.2.我们为了保护您或他⼈的合法权益会结合您对我们提供的各产品的使⽤情况判断是否⽀持您的
      注销请求，⽐如若您的付费服务期限未满，则我们不会⽴即⽀持您的请求，⽽会提⽰您先进⾏相应处
      理。
    </p>
    <p class="zhengceTit">
      6.6.3.除法律法规另有规定外，注销账号之后，您该账号内的所有信息将被清空，我们将并根据您的
      要求删除您的个⼈信息。您通过第三⽅账号（如微信、微博、QQ等）授权登录我们的服务时，需要
      向第三⽅申请注销账号。
    </p>
    <p class="zhengceTit">6.7. 提前获知产品和服务停⽌运营</p>
    <p class="zhengceTit">
      我们愿⼀直陪伴您，若因特殊原因导致我们部分或全部产品和服务被迫停⽌运营，我们将提前15⽇
      在产品或服务的主⻚⾯或站内信或向您发送电⼦邮件或其他合适的能触达您的⽅式通知您，并将停⽌
      对您个⼈信息的收集，同时会按照法律规定对所持有的您的个⼈信息进⾏删除或匿名化处理等，法律
      法规另有规定的除外。
    </p>
    <p class="zhengceTit">6.8. 要求解释说明个⼈信息处理规则</p>
    <p class="zhengceTit">
      在某些业务功能中，我们可能存在依据信息系统、算法等在内的⾃动化决策机制做出某些商业决定，
      如向您展⽰个性化推荐内容。如果您认为这些决定显著影响您的合法权益，您有权要求我们对处理您
      个⼈信息的规则做出解释，我们也将相应为您提供充分的救济⽅式。
    </p>
    <p class="zhengceTit">6.9. 响应您的上述请求</p>
    <p class="zhengceTit">
      为保障安全，您可能需要提供书⾯请求，或以其他⽅式证明您的⾝份。我们可能会先要求您验证⾃⼰
      的⾝份，然后再处理您的请求。
    </p>
    <p class="zhengceTit">
      6.9.1.对于您合理的请求，我们原则上不收取费⽤，但对多次重复、超出合理限度的请求，我们将视
      情况收取⼀定成本费⽤。对于那些⽆端重复、需要过多技术⼿段（例如，需要开发新系统或从根本上
      改变现⾏惯例）、给他⼈合法权益带来⻛险或者⾮常不切实际（例如，涉及备份磁带上存放的信息）
      的请求，我们可能会予以拒绝，并向您具体说明拒绝的理由。
    </p>
    <p class="zhengceTit">
      6.9.2. 但请注意，在以下情形中，按照
      法律法规要求，我们将⽆法响应您的更正、删除、注销信息等请求：
    </p>
    <p class="zhengceTit">(a) 与国家安全、国防安全直接相关的；</p>
    <p class="zhengceTit">(b) 与公共安全、公共卫⽣、重⼤公共利益直接相关的；</p>
    <p class="zhengceTit">(c) 与犯罪侦查、起诉、审判和判决执⾏等直接相关的；</p>
    <p class="zhengceTit">(d) 有充分证据表明您存在主观恶意或滥⽤权利的</p>
    <p class="zhengceTit">
      (e) 响应您的请求将导致您或其他个⼈、组织的合法权益受到严重损害的；
    </p>
    <p class="zhengceTit">(f) 涉及商业秘密的。</p>
    <span class="xiaoti">7. 我们如何处理未成年⼈的个⼈信息</span>
    <p class="zhengceTit">
      7.1.
      我们主要⾯向成⼈提供产品和服务。如果没有⽗⺟或监护⼈的同意，未成年⼈不得创建⾃⼰的⽤
      ⼾账⼾。如您为不满14周岁的未成年⼈，我们要求您请您的⽗⺟或监护⼈仔细阅读本协议，并在征
      得您的⽗⺟或监护⼈同意的前提下使⽤我们的服务或向我们提供信息。
    </p>
    <p class="zhengceTit">
      7.2.
      对于经⽗⺟或监护⼈同意使⽤我们的产品或服务⽽收集未成年⼈个⼈信息的情况，我们只会在法
      律法规允许、⽗⺟或监护⼈明确同意或者保护未成年⼈所必要的情况下使⽤、共享、转让或披露此信
      息。
    </p>
    <p class="zhengceTit">
      7.3.
      如果我们发现⾃⼰在未事先获得可证实的⽗⺟同意的情况下收集了未成年⼈的个⼈信息，则会设
      法尽快删除相关数据，并且我们不承担由于此等删除对您造成的损失。
    </p>
    <p class="zhengceTit">
      7.4.
      如果任何时候监护⼈有理由相信我们在未获监护⼈同意的情况下收集了未成年⼈的个⼈信息，请
      通过本协议第10条指定的联系⽅式联系我们，我们会采取措施尽快删除相关数据。
    </p>
    <span class="xiaoti">8. 您的个⼈信息如何在全球范围转移</span>
    <p class="zhengceTit">
      8.1.
      我们在中华⼈⺠共和国境内运营中收集和产⽣的个⼈信息，存储在中国境内，以下情形除外：
    </p>
    <p class="zhengceTit">8.1.1.法律法规有明确规定；</p>
    <p class="zhengceTit">8.1.2.获得您的明确授权；</p>
    <p class="zhengceTit">8.1.3.您通过互联⽹进⾏跨境交易等个⼈主动⾏为。</p>
    <p class="zhengceTit">
      8.2. 针对以上情形，我们会确保依据本协议对您的个⼈信息提供⾜够的保护。
    </p>
    <span class="xiaoti">9. 本协议如何更新</span>
    <p class="zhengceTit">
      9.1.
      如果互联⽹信息服务提供商的同⾏实践，由于产品和服务不断更新变化，为了全⾯、适时地向您
      告知个⼈信息处理规则，我们的隐私协议可能变更。
    </p>
    <p class="zhengceTit">
      9.2.
      未经您明确同意，我们不会削减您按照本协议所应享有的权利。我们会在专⻔⻚⾯上发布对隐私
      协议所做的任何变更。
    </p>
    <p class="zhengceTit">
      9.3.
      对于重⼤变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过⽹站公⽰的⽅式
      进⾏通知甚⾄向您提供弹窗提⽰，说明隐私权协议的具体变更内容），并会在变更⽣效前给您预留合
      理的期限供您考虑是否接受变更。若您在新版本隐私协议和⽤⼾协议⽣效后继续使⽤我们的产品和服
      务，即表⽰您已充分阅读并愿意受更新后的隐私协议和⽤⼾协议约束，若您不同意该等变更可以停⽌
      使⽤我们的产品和服务。
    </p>
    <p class="zhengceTit">9.4. 本协议所指的重⼤变更包括但不限于：</p>
    <p class="zhengceTit">
      9.4.1.我们的服务模式发⽣重⼤变化。如处理个⼈信息的⽬的、处理的个⼈信息类型、个⼈信息的使
      ⽤⽅式等；
    </p>
    <p class="zhengceTit">
      9.4.2.我们在所有权结构、组织架构等⽅⾯发⽣重⼤变化。如业务调整、破产并购等引起的所有者变
      更等；
    </p>
    <p class="zhengceTit">
      9.4.3. 个⼈信息共享、转让或公开披露的主要对象发⽣变化；
    </p>
    <p class="zhengceTit">
      9.4.4. 您参与个⼈信息处理⽅⾯的权利及其⾏使⽅式发⽣重⼤变化；
    </p>
    <p class="zhengceTit">
      9.4.5. 我们负责处理个⼈信息安全的责任部⻔、联络⽅式及投诉渠道发⽣变化时；
    </p>
    <p class="zhengceTit">9.4.6. 个⼈信息安全影响评估报告表明存在⾼⻛险时。</p>
    <span class="xiaoti">10. 如何联系我们</span>
    <p class="zhengceTit">
      您对本协议内容有任何疑问和意⻅，或者您对你拖我拉对本协议的实践以及操作上有任何疑问和意
      ⻅，请与我们联系。您可以将您的问题发送⾄service@nituowola.com。
    </p>
    <p class="zhengceTit">⼀般情况下，我们将在15⽇内回复您的请求。</p>
    <p class="zhengceTit">
      如果您对我们的回复不满意，特别是您认为我们的个⼈信息处理⾏为损害了您的合法权益，您还可以
      通过向你拖我拉住所地⼈⺠法院提起诉讼来寻求解决⽅案。
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.xieyibox {
  background-color: #f8fafc;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1440px;
  padding: 0 10px;
}

.zhengce {
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}

.zhengceTit {
  text-indent: 2em;
  font-size: 14px;
  margin-top: 3px;
  line-height: 25px;
}

.biaoti {
  font-weight: 500;
  margin-top: 5px;
}

.xiaoti {
  margin-top: 5px;
}
</style>